import React, { useContext, useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import {
  Layout,
  ImageGallery,
  ProductQuantityAdder,
  FeaturedProducts,
  Tabs,
  ResponsiveSlider,
} from 'components';

import CartContext from 'context/CartContext';
import { navigate, useLocation } from '@reach/router';
import queryString from 'query-string';
import {
  Grid,
  SelectWrapper,
  Price,
  Title,
  Description,
  Message,
  ProductWrapper
} from './styles';
import { Select } from '@shopify/polaris';
import ProductContext from '../../context/ProductContext';

export const query = graphql`
  query ProductQuery($shopifyId: String, $featuredProductId: String) {
    shopifyProduct(shopifyId: { eq: $shopifyId }) {
      ...ShopifyProductFields
    }
    allShopifyProduct(
      limit: 3
      filter: {
        tags: { eq: $featuredProductId }
        shopifyId: { ne: $shopifyId }
      }
    ) {
      edges {
        node {
          handle
          shopifyId
          tags
          title
          description
          priceRange {
            minVariantPrice {
              amount
            }
          }
          variants {
            shopifyId
          }
          images {
            id
            localFile {
              childImageSharp {
                fluid(maxWidth: 1200) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default function ProductTemplate(props) {
  const [isOpen, setIsOpen] = useState(false);
  const { getProductById } = useContext(CartContext);
  const { currentTranslate } = useContext(ProductContext);
  const [product, setProducts] = useState(null);
  const [selectVariant, setSelectVariant] = useState(null);
  const { search, origin, pathname } = useLocation();
  const variantId = queryString.parse(search).variant;

  useEffect(() => {
    getProductById(props.data.shopifyProduct.shopifyId).then(result => {
      setProducts(result);
      setSelectVariant(
        result.variants.find(({ id }) => id === variantId) || result.variants[0]
      );
    });
  }, [
    getProductById,
    setProducts,
    props.data.shopifyProduct.shopifyId,
    variantId,
  ]);

  const handleVariant = e => {
    const newVariant = product?.variants.find(v => v.id === e);
    setSelectVariant(newVariant);
    navigate(
      `${origin}${pathname}?variant=${encodeURIComponent(newVariant.id)}`,
      { replace: true }
    );
  };

  const handleMessage = () => {
    setIsOpen(true);
    setTimeout(() => {
      setIsOpen(false);
    }, 3000);
  };

  let optionsValues = [];
  const { allShopifyProduct } = props.data;
  const featured = allShopifyProduct ? allShopifyProduct.edges : [];

  return (
    <Layout>
      <Grid>
        <div>
          <Title>{props.data.shopifyProduct.title}</Title>
          <ResponsiveSlider
            selectedVariantImageId={selectVariant?.image.id}
            images={props.data.shopifyProduct.images}
          />
          {!!selectVariant && (
            <Price id="mobile-price">$ {selectVariant.price}</Price>
          )}
          <Description>{props.data.shopifyProduct.description}</Description>
          {props.data.shopifyProduct.productType !== 'Canasta' && (
            <Tabs metafields={props.data.shopifyProduct.metafields} />
          )}
          {product?.availableForSale && !!selectVariant && (
            <>
              {product?.variants.length > 1 && (
                <SelectWrapper>
                  {product?.variants.map(item => {
                    optionsValues.push({
                      label: item.title,
                      value: item.id,
                    });
                  })}
                  <Select
                    label="Variantes"
                    options={optionsValues}
                    onChange={handleVariant}
                    value={selectVariant.id}
                  />
                </SelectWrapper>
              )}
            </>
          )}
        </div>
        <ProductWrapper>
          <ImageGallery
            selectedVariantImageId={selectVariant?.image.id}
            images={props.data.shopifyProduct.images}
          />
          {!!selectVariant && (
            <>
              <Price id="desktop-price">$ {selectVariant.price}</Price>
              {isOpen && <Message>{currentTranslate.cart.addedToCart}</Message>}
              <ProductQuantityAdder
                variantId={selectVariant.id}
                available={selectVariant.available}
                messageFunc={handleMessage}
              />
            </>
          )}
        </ProductWrapper>
      </Grid>
      <FeaturedProducts products={featured} interno={true} />
    </Layout>
  );
}
