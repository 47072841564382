import styled from "styled-components";

export const Grid = styled.section`
  padding-top: 30px;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 20px;
  #mobile-price {
    display: none;
  }
  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
    > div:first-child {
      order: 2;
    }
    > div:last-child {
      order: 1;
    }
  }
  @media (max-width: 500px) {
    button {
      width: 142px;
      height: 57px;
    }
    .Polaris-Label {
      margin-top: 20px;
      label {
        font-weight: bold;
        font-size: 16px;
        line-height: 19px;
        color: #322665;
      }
    }
    #mobile-price {
      display: inline-block;
      margin: 20px 0;
    }
    #desktop-price {
      display: none;
    }
    section:nth-child(2) {
      display: none;
    }
  }
`;

export const SelectWrapper = styled.div`
  margin-top: 40px;
  .Polaris-Select {
    z-index: 0;
  }

  > strong {
    display: block;
    margin-bottom: 8px;
  }
  @media (max-width: 500px) {
    margin-top: 0px;
  }
`;

export const Price = styled.div`
  margin: 40px 0;
  font-size: 28px;
  line-height: 33px;
  font-weight: bold;
  color: #322665;
  @media (max-width: 500px) {
    margin-top: 0px;
  }
`;

export const Title = styled.p`
  font-weight: 600;
  font-size: 26px;
  line-height: 30px;
  color: #322665;
  margin-top: 20px;
`;

export const Description = styled.p`
  font-size: 18px;
  line-height: 22px;
  color: #000000;
  margin-top: 20px;
  @media (max-width: 500px) {
    margin-top: 0px;
  }
`;

export const Message = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #50af69;
  margin-bottom: 10px;
`;

export const ProductWrapper = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: 500px) {
    #gallery-wrapper {
      display: none;
    }
  }
`;
